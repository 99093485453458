import { ReactElement } from "react";
import { graphql } from "gatsby";
import Helmet from "../../components/Helmet";
import SupportItem from "../../components/Support/SupportItem";
import { heroImage } from "../../components/Icons";
import * as styles from "./Support.module.scss";

interface SupportProps {
    data: GatsbyTypes.SupportPageQuery;
}

export default function SupportPage({ data }: SupportProps): ReactElement {
    const title = "Support";
    const description = "";

    return (
        <>
            <Helmet title={title} description={description} />
            <section className={styles.support}>
                <div className="headerMainContainer">
                    <div className="mainBox">
                        <h1>{data.strapi.supportPage?.base?.heading}</h1>
                    </div>
                    <div className="headerImage">{heroImage}</div>
                </div>

                <div className="mainBox">
                    <div className={styles.inner}>
                        <div className={styles.items}>
                            {data.strapi.supportPage?.supportItems?.map(x => (
                                <SupportItem key={x?.id} {...(x as GatsbyTypes.strapi_ComponentWidgetsSupport)} />
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export const query = graphql`
    query SupportPage {
        strapi {
            supportPage {
                base {
                    heading
                }
                supportItems {
                    ... on strapi_ComponentWidgetsSupport {
                        id
                        base {
                            heading
                            paragraph
                        }
                        cta {
                            text
                            link
                            external
                        }
                        iconSvg
                    }
                }
            }
        }
    }
`;
