import { ReactElement } from "react";
import CtaLink from "../../Shared/CtaLink";
import { className } from "../../../shared/ClassName";
import { arrows } from "../../Icons";
import * as styles from "./SupportItem.module.scss";

export default function SupportItem(props: GatsbyTypes.strapi_ComponentWidgetsSupport): ReactElement {
    return (
        <>
            {props.cta && (
                <div {...className(styles.item, props.iconSvg ? "" : styles.noIconItem)}>
                    <CtaLink cta={props.cta}>
                        <div {...className(styles.iconAside)}>{props.iconSvg && <div dangerouslySetInnerHTML={{ __html: props.iconSvg }}></div>}</div>
                        <div {...className(styles.textContainer)}>
                            <div>
                                <h2>{props.base?.heading}</h2>
                                <div dangerouslySetInnerHTML={{ __html: props.base?.paragraph ?? "" }}></div>
                            </div>

                            <div className={styles.supportButton}>
                                <div className={styles.innerButton}>
                                    <p>{props.cta.text}</p>
                                    <div className={styles.iconContainer}>{arrows.right}</div>
                                </div>
                            </div>
                        </div>
                    </CtaLink>
                </div>
            )}
        </>
    );
}
